<!--新增/编辑收货地址 @zjf-2021-01-06-->
<template>
	<div class="address_operate_modal">
		<el-dialog :title="type == 'edit' ? L['编辑地址'] : L['添加收货地址']" v-model="modalVisible" @close="close_dialog">
			<el-form :model="form.data" :rules="rules" ref="ruleForm">
				<el-form-item :label="L['收货人']" :label-width="formLabelWidth" prop="memberName">
					<el-input v-model="form.data.memberName" autocomplete="off" maxlength="25" show-word-limit
						:placeholder="L['请输入收货人姓名']" clearable>
					</el-input>
				</el-form-item>
				<div class="phone">
					<el-form-item :label="L['电话号码']" :label-width="formLabelWidth" prop="telMobile">
						<div class="area-code">
							<img class="area-code-img" :src="areaCodeData.img" alt="">
							<el-select v-model="form.data.areaCode" @change="changeAreaCode" :popper-append-to-body="false">
								<el-option :label="item.code" :value="index" v-for="(item, index) in areaMobileList"
									:key="index" @click="form.data.areaCode = item.code">
									<div class="area-code-item">
										<img class="flagImg" :src="item.img" alt="">
										<span>{{ L[item.cn] }} {{ item.code }}</span>
									</div>
								</el-option>
							</el-select>
						</div>
						<el-input style="width: 340px;" v-model="form.data.telMobile" autocomplete="off" :maxlength="telMobileMaxlength"
							@input="filterNonNumeric"
							:placeholder="L['请输入手机号']" clearable show-word-limit></el-input>
					</el-form-item>
				</div>


				<!-- <el-form-item :label="L['所在地区']" :label-width="formLabelWidth" prop="area">
					<el-cascader ref="cascaderAddr" v-model="form.data.area" :options="areaDataArray"
						:placeholder="L['请选择所在地区']" :props="cascaderProps" clearable></el-cascader>
				</el-form-item> -->

				<el-form-item :label="L['详细地址']" :label-width="formLabelWidth" prop="detailAddress">
					<div class="add_con">
						<el-input type="textarea" :rows="2" v-model="form.data.detailAddress" autocomplete="off"
						 @blur="addressBlur" @input="addressChange" :placeholder="L['请输入详细地址']" clearable maxlength="40"></el-input>
						 <!-- addressDownShow -->
						 <div class="add_down_con" v-show="addressDownShow">
							<div class="add_down_list">
								<div class="add_down_item" @click.stop="clickAddDownInfo(item)" v-for="(item,index) in addressDownInfo" :key="index">
									{{ item.addressKaz }}
								</div>
								<!-- <div class="add_down_item">
									Покровка Мамлютский район Северо-Казахстанская область 150912
								</div> -->
							</div>
						</div>
					</div>
				</el-form-item>

				<el-form-item :label-width="formLabelWidth">
					<el-input :disabled="is_disabled" v-model="form.data.street" autocomplete="off" show-word-limit 
					:placeholder="L['请输入街道']" clearable>
					</el-input>
				</el-form-item>

				<el-form-item :label-width="formLabelWidth">
					<div class="flex">
						<el-input :disabled="is_disabled" style="margin-right: 10px;" v-model="form.data.houseNumber" autocomplete="off"
							show-word-limit :placeholder="L['请输入门牌号']" clearable>
						</el-input>
						<el-input v-model="form.data.unit" autocomplete="off" show-word-limit
							:placeholder="L['请输入单元/办公室']" clearable>
						</el-input>
					</div>
				</el-form-item>

				<el-form-item :label-width="formLabelWidth">
					<el-input v-model="form.data.postCode" autocomplete="off" show-word-limit 
					:placeholder="L['请输入邮编']" clearable>
					</el-input>
				</el-form-item>

				<el-form-item label=" " :label-width="formLabelWidth">
					<el-checkbox v-model="form.data.isDefault">{{ L['设为默认地址'] }}</el-checkbox>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="close_dialog">{{ L['取 消'] }}</el-button>
					<el-button type="primary" @click="addAddress('ruleForm')" :loading="btnLoading">{{ L['确 定']
					}}</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import { areaMobile } from '@/utils/data.js'
import { ref, reactive, getCurrentInstance, watchEffect,onMounted } from "vue";
import areaData from "../assets/area.json";
import { ElMessage } from "element-plus";
export default {
	name: "SldAddressOperation",
	components: {},
	props: ["visibleFlag", "type", "addressInfo"],
	setup(props, { emit }) {
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const formLabelWidth = "80px";
		const modalVisible = ref(true);
		const form = reactive({ data: {} });
		const areaDataArray = areaData;
		const cascaderProps = { label: "regionName", value: "regionCode" };
		const ruleForm = ref(null);
		const cascaderAddr = ref(null);
		const addressInfo = reactive(props.addressInfo);
		const btnLoading = ref(false)
		const type = ref(props.type)
		// const areaCode = ref('+7')
		const areaMobileList = reactive(areaMobile)
		const areaCodeData = ref(areaMobileList[0])
		const addressDownShow = ref(false)
		const addressDownInfo = ref([])
		const is_disabled = ref(false)
		const telMobileMaxlength = ref(10)
		// 切换地区
		const changeAreaCode = (value) => {
			areaCodeData.value = areaMobileList[value]
			if(areaMobileList[value].code == "+86"){
				telMobileMaxlength.value = 11
			}else{
				telMobileMaxlength.value = 10
			}
		}
		if (props.type == "edit") {
			// var area = [addressInfo.data.provinceCode, addressInfo.data.cityCode];
			form.data.memberName = addressInfo.data.memberName;
			form.data.telMobile = addressInfo.data.telMobile;
			form.data.detailAddress = addressInfo.data.detailAddress; //详细地址
			form.data.isDefault = addressInfo.data.isDefault ? true : false; //是否默认地址：1-默认地址，0-非默认地址
			// 国旗
			let areaCodeDataTemp = areaMobileList.find(country => country.regionCode == addressInfo.data.country);
			areaCodeData.value = areaCodeDataTemp
			form.data.areaCode = addressInfo.data.areaCode;
			// if (addressInfo.data.districtCode != '0') {
			// 	area.push(addressInfo.data.districtCode)
			// }
			// form.data.area = area;

			form.data.residence = addressInfo.data.detailAddress,//住所
			form.data.street = addressInfo.data.street,//街道
			form.data.unit = addressInfo. data.unit,//单元/办公室
			form.data.postCode = addressInfo.data.postCode,//邮政编码
			form.data.houseNumber = addressInfo.data.houseNumber,//门牌号
			is_disabled.value = addressInfo.data.isResidence==1?true:false//	是否是通过搜索出来的住所（0:否、1:是）
				
		} else {
			form.data.memberName = "";
			form.data.telMobile = "";
			form.data.detailAddress = ""; //详细地址
			form.data.isDefault = false; //是否默认地址：1-默认地址，0-非默认地址
			// form.data.area = [];
			form.data.areaCode = '+7'
			
			form.data.residence = '',//住所
			form.data.street = '',//街道
			form.data.unit = '',//单元/办公室
			form.data.postCode = '',//邮政编码
			form.data.houseNumber = ''//门牌号
				
		}
		console.log(L["请输入收货人"], 'L["请输入收货人"]')

		const rules = {
			memberName: [
				{ required: true, message: L["请输入收货人"], trigger: "change" },
				{ min: 2, max: 25, message: L["请输入2~25个字符"], trigger: "change" }
			],
			telMobile: [
				{ required: true, message: L["请输入手机号"], trigger: "change" },
				{
					// pattern: /^((\+?86)|(\(\+86\)))?(1[3-9]\d{9}$)|(\d{4}-)\d{6,8}$/,
					message: L["请输入正确的电话号码"],
					trigger: "change"

				}
			],
			// area: [{ required: true, message: L["请选择所在地区"], trigger: "change" }],
			detailAddress: [
				{ required: true, message: L["请输入详细地址"], trigger: "change" },
				// { min: 5, max: 40, message: L["请输入5~40个字符"], trigger: "change" },
				{
					// pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/,
					// message: L["请输入正确的地址"],
					// trigger: "change"
				}
			]
		}; //输入校验
		//增加地址
		const addAddress = () => {
			btnLoading.value = true
			if (!form.data.memberName.trim()) {
				ElMessage.error(L['请输入收货人']);
				btnLoading.value = false
				return false
			}
			if (form.data.areaCode == "+7") {
				if (form.data.telMobile.trim().length != 10) {
					ElMessage.error(L['请输入正确的电话号码']);
					btnLoading.value = false
					return false
				}
			} else {
				if (form.data.telMobile.trim().length != 11) {
					ElMessage.error(L['请输入正确的电话号码']);
					btnLoading.value = false
					return false
				}
			}
			if (!form.data.detailAddress.trim()) {
				ElMessage.error(L['请输入详细地址']);
				btnLoading.value = false
				return false
			}
			if(is_disabled.value == false){
				if(!form.data.street.trim()){
					ElMessage.error(L['请填写街道信息']);
					btnLoading.value = false
					return
				}
				if(!form.data.houseNumber.trim()){
					ElMessage.error(L['请填写门牌号']);
					btnLoading.value = false
					return
				}
			}
			if (!form.data.unit.trim()) {
				ElMessage.error(L['请填写单元/办公室']);
				btnLoading.value = false
				return false
			}
			if (!form.data.postCode.trim()) {
				ElMessage.error(L['请填写邮编']);
				btnLoading.value = false
				return false
			}

			console.log(form.data)
			ruleForm.value.validate(valid => {
				if (valid) {

					var param = {
						// provinceCode: form.data.area[0],
						// cityCode: form.data.area[1],
						// districtCode: form.data.area[2],
						memberName: form.data.memberName.trim(),
						telMobile: form.data.telMobile.trim(),
						// addressAll: cascaderAddr.value
						// 	.getCheckedNodes()[0]
						// 	.pathLabels.join(""),
						isDefault: form.data.isDefault ? 1 : 0,
						detailAddress: form.data.detailAddress.trim(),
						areaCode: form.data.areaCode,
						country: areaCodeData.value.regionCode,

						residence : form.data.detailAddress,//住所
						street : form.data.street,//街道
						unit :form. data.unit,//单元/办公室
						postCode : form.data.postCode,//邮政编码
						houseNumber : form.data.houseNumber,//门牌号
						isResidence : is_disabled.value==true?1:0//	是否是通过搜索出来的住所（0:否、1:是）
							
					};

					var url;
					if (props.type == "edit") {
						url = "v3/member/front/memberAddress/edit";
						param.addressId = addressInfo.data.addressId
					} else if (props.type == "add") {
						url = "v3/member/front/memberAddress/add";
					}
					proxy
						.$post(url, param)
						.then(res => {
							if (res.state == 200) {
								btnLoading.value = false
								close_dialog();
								emit("refreshAddress", param, res.data);
								ElMessage.success(res.msg);
							} else {

								ElMessage(res.msg);
							}
						})
				} else {
					btnLoading.value = false
					return false;
				}
			});
		};
		const close_dialog = () => {
			emit("close");
			document.body.style.overflow = 'visible'
		};
		
		const addressChange = (e) => {
			let param = {
				'query': e
			}
			proxy.$get("postKz/postcode/search", param).then(res => {
				if (res.status == 0) {
					if(res.data){
						addressDownInfo.value = res.data
						addressDownShow.value = true
					}else{
						addressDownShow.value = false
						is_disabled.value = false
					}
				} else {
					// ElMessage(res.msg);
					addressDownShow.value = false
					is_disabled.value = false
				}
			})
		}
		const addressBlur = () => {
			// addressDownShow.value = false
			is_disabled.value = false
			setTimeout(function(){
				addressDownShow.value = false
			},300)
		}
		const clickAddDownInfo = (item) => {
			form.data.detailAddress = item.addressKaz
			form.data.postCode = item.postcode
			addressDownShow.value = false
			form.data.street = ""
			form.data.houseNumber = ""
			form.data.unit = ""
			is_disabled.value = true
		}
		const filterNonNumeric = () => {
			 // 使用正则表达式过滤非数字字符
			 form.data.telMobile= form.data.telMobile.replace(/\D/g, "");
		}
		onMounted(() => {
			if(form.data.areaCode == '+86'){
				telMobileMaxlength.value = 11
			}
		})
		watchEffect(() => {
			//   modalVisible.value = props.visibleFlag;
			//   console.log(addressInfo)
		});
		return {
			L,
			filterNonNumeric,
			telMobileMaxlength,
			is_disabled,
			clickAddDownInfo,
			addressDownInfo,
			addressDownShow,
			addressChange,
			addressBlur,
			// areaCode,
			areaCodeData,
			areaMobileList,
			changeAreaCode,
			formLabelWidth,
			modalVisible,
			form,
			areaDataArray,
			cascaderProps,
			rules,
			ruleForm,
			addAddress,
			cascaderAddr,
			close_dialog,
			btnLoading,
			type,
		};
	}
};
</script>

<style lang="scss" >
.phone {
	.el-form-item__content {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.area-code {
	width: 120px;
	margin-top: 3px;
	margin-left: 3px;
	position: relative;

	.area-code-img {
		width: 24px;
		height: 24px;
		position: absolute;
		z-index: 9;
		left: 8px;
		top: 8px;
		border-radius: 100%;
	}

	.el-input__inner {
		border: none;
		padding: 0;
		background: #EFF0FF;
		color: #1B1B1B;
		font-size: 16px;
		padding-left: 36px;
		width: 120px;
	}
}

.area-code-item {
	display: flex;
	align-items: center;

	.flagImg {
		height: 20px;
		margin-right: 13px;
		width: 20px;
		border-radius: 100%;
	}
}

.add_con {
	position: relative;

	.add_down_con {
		position: absolute;
		left: 0;
		top: 54px;
		z-index: 9;
		display: flex;
		width: 480px;
		padding: 8px 0px;
		flex-direction: column;
		align-items: flex-start;
		flex: 1 0 0;
		border-radius: 4px;
		background: #FFF;
		/* Shadows/Elevate/3 */
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);

		.add_down_list {
			.add_down_item {
				display: flex;
				padding: 12px;
				justify-content: space-between;
				align-items: center;
				align-self: stretch;

				color: #1B1B1B;

				/* Body/Large */
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				cursor: pointer;
			}
			.add_down_item:hover{
				background: #EFF0FF;
			}
		}
	}
}
</style>
<style lang="scss">
.el-cascader-menu__wrap {
	height: 204px !important;
}

textarea,
.el-textarea__inner {
	resize: none !important;

	&:focus {
		border-color: var(--color_main);
	}
}

.address_operate_modal {
	.el-form-item__label {
		line-height: inherit;
	}

	.el-dialog {
		width: 600px !important;
	}

	.el-cascader {
		width: 100% !important;

		.el-input {
			.el-input__inner {
				&:focus {
					border-color: var(--color_main);
				}
			}
		}
	}

	.el-checkbox {
		.el-checkbox__input {
			margin-top: -4px;
		}
	}

	.el-button--primary {
		color: #fff;
		background-color: var(--color_main);
		border-color: var(--color_main);
	}

	.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: var(--color_main);
		border-color: var(--color_main);
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: var(--color_main);
	}

	.el-button {
		padding: 9px 20px;
	}

	.el-dialog__body {
		padding: 20px;
	}

	.el-form-item {
		margin-bottom: 15px;
	}

	.el-form-item__content {
		position: relative;
	}

	.el-form-item__error {
		position: absolute;
		top: -2px;
		right: 0;
		display: inline-block;
		width: auto;
		z-index: 2;
		background: #fff;
		left: auto;
		padding: 0 3px;
	}

	input::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: #ccc;
		font-size: 12px;
	}

	input::-moz-placeholder {
		/* Firefox 19+ */
		color: #ccc;
		font-size: 12px;
	}

	input:-ms-input-placeholder {
		/* IE 10+ */
		color: #ccc;
		font-size: 12px;
	}

	input:-moz-placeholder {
		/* Firefox 18- */
		color: #ccc;
		font-size: 12px;
	}

	textarea::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: #ccc;
		font-size: 12px;
	}

	textarea::-moz-placeholder {
		/* Firefox 19+ */
		color: #ccc;
		font-size: 12px;
	}

	textarea:-ms-input-placeholder {
		/* IE 10+ */
		color: #ccc;
		font-size: 12px;
	}

	textarea:-moz-placeholder {
		/* Firefox 18- */
		color: #ccc;
		font-size: 12px;
	}

	.address_operate_modal .el-input {
		--el-input-focus-border: var(--color_main);
		width: 100%;
	}

	.el-cascader-node.in-active-path {
		color: var(--color_main) !important;
	}
}
</style>
