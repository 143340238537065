/**
 *  定义的常量数据
 *
 */

export const areaMobile = [
		{
		  cn: "哈萨克斯坦",
		  regionCode: "KZ",
		  code: "+7",
		  flag: "🇰🇿",
		  en: "Kazakhstan",
		  img:"https://static.antdata.cc/images/KZ.png"
		},
		{
		  cn: "俄罗斯",
		  regionCode: "RU",
		  code: "+7",
		  flag: "🇷🇺",
		  en: "Russia",
		  img:"https://static.antdata.cc/images/RU.png"
		},
		{
		  cn: "中国",
		  regionCode: "CN",
		  code: "+86",
		  flag: "🇨🇳",
		  en: "China mainland",
		  img:"https://static.antdata.cc/images/CN.png"
		}
	]
	

export const emojiPath = 'https://images.slodon.cn/java/bbc/mobile/emoji/'


export const emoji = [
    {
        // title: '羊驼',
		title: '',
        src: 'emo_01.gif'
    },
    // {
    //     title: '神马',
    //     src: 'emo_02.gif'
    // },
    {
        // title: '浮云',
		title: '',
        src: 'emo_03.gif'
    },
    // {
    //     title: '给力',
    //     src: 'emo_04.gif',
    // },
    {
        // title: '围观',
		title: '',
        src: 'emo_05.gif'
    },
    {
        // title: "威武",
		title: '',
        src: "emo_06.gif"
    },
    {
        // title: "熊猫",
		title: '',
        src: "emo_07.gif"
    },
    {
        // title: "兔子",
		title: '',
        src: "emo_08.gif"
    },
    {
        // title: "奥特曼",
		title: '',
        src: "emo_09.gif"
    },
    {
        // title: "囧",
		title: '',
        src: "emo_10.gif"
    },
    // {
    //     title: "互粉",
    //     src: "emo_11.gif"
    // },
    {
        // title: "礼物",
		title: '',
        src: "emo_12.gif"
    },
    {
        // title: "微笑",
		title: '',
        src: "emo_13.gif"
    },
    {
        // title: "嘻嘻",
		title: '',
        src: "emo_14.gif"
    },
    {
        // title: "哈哈",
		title: '',
        src: "emo_15.gif"
    },
    {
        // title: "可爱",
		title: '',
        src: "emo_16.gif"
    },
    {
        // title: "可怜",
		title: '',
        src: "emo_17.gif"
    },
    {
        // title: "抠鼻",
		title: '',
        src: "emo_18.gif"
    },
    {
        // title: "吃惊",
		title: '',
        src: "emo_19.gif"
    },
    {
        // title: "害羞",
		title: '',
        src: "emo_20.gif"
    },
    {
        // title: "调皮",
		title: '',
        src: "emo_21.gif"
    },
    {
        // title: "闭嘴",
		title: '',
        src: "emo_22.gif"
    },
    {
        // title: "鄙视",
		title: '',
        src: "emo_23.gif"
    },
    {
        // title: "爱你",
		title: '',
        src: "emo_24.gif"
    },
    {
        // title: "流泪",
		title: '',
        src: "emo_25.gif"
    },
    {
        // title: "偷笑",
		title: '',
        src: "emo_26.gif"
    },
    {
        // title: "亲亲",
		title: '',
        src: "emo_27.gif"
    },
    {
        // title: "生病",
		title: '',
        src: "emo_28.gif"
    },
    {
        // title: "太开心",
		title: '',
        src: "emo_29.gif"
    },
    {
        // title: "白眼",
		title: '',
        src: "emo_30.gif"
    },
    {
        // title: "右哼哼",
		title: '',
        src: "emo_31.gif"
    },
    {
        // title: "左哼哼",
		title: '',
        src: "emo_32.gif"
    },
    {
        // title: "嘘",
		title: '',
        src: "emo_33.gif"
    },
    {
        // title: "衰",
		title: '',
        src: "emo_34.gif"
    },
    {
        // title: "委屈",
		title: '',
        src: "emo_35.gif"
    },
    {
        // title: "呕吐",
		title: '',
        src: "emo_36.gif"
    },
    {
        // title: "打哈欠",
		title: '',
        src: "emo_37.gif"
    },
    {
        // title: "抱抱",
		title: '',
        src: "emo_38.gif"
    },
    {
        // title: "怒",
		title: '',
        src: "emo_39.gif"
    },
    {
        // title: "问号",
		title: '',
        src: "emo_40.gif"
    },
    {
        // title: "馋",
		title: '',
        src: "emo_41.gif"
    },
    {
        // title: "拜拜",
		title: '',
        src: "emo_42.gif"
    },
    {
        // title: "思考",
		title: '',
        src: "emo_43.gif"
    },
    {
        // title: "汗",
		title: '',
        src: "emo_44.gif"
    },
    {
        // title: "打呼",
		title: '',
        src: "emo_45.gif"
    },
    {
        // title: "睡",
		title: '',
        src: "emo_46.gif"
    },
    {
        // title: "钱",
		title: '',
        src: "emo_47.gif"
    },
    {
        // title: "失望",
		title: '',
        src: "emo_48.gif"
    },
    {
        // title: "酷",
		title: '',
        src: "emo_49.gif"
    },
    {
        // title: "好色",
		title: '',
        src: "emo_50.gif"
    },
    {
        // title: "生气",
		title: '',
        src: "emo_51.gif"
    },
    {
        // title: "鼓掌",
		title: '',
        src: "emo_52.gif"
    },
    {
        // title: "晕",
		title: '',
        src: "emo_53.gif"
    },
    {
        // title: "悲伤",
		title: '',
        src: "emo_54.gif"
    },
    {
        // title: "抓狂",
		title: '',
        src: "emo_55.gif"
    },
    {
        // title: "黑线",
		title: '',
        src: "emo_56.gif"
    },
    {
        // title: "阴险",
		title: '',
        src: "emo_57.gif"
    },
    {
        // title: "怒骂",
		title: '',
        src: "emo_58.gif"
    },
    {
        // title: "心",
		title: '',
        src: "emo_59.gif"
    },
    {
        // title: "伤心",
		title: '',
        src: "emo_60.gif"
    }
]

